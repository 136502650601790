<template>
  <div>
    <div ref="chartRef" style="height: 400px; width: 100%;"></div>
  </div>
</template>

<script setup>
import { ref, onUpdated} from 'vue';
import * as echarts from 'echarts';
import { defineProps } from "vue"

const props = defineProps({
  dynamicProp: {
    type: Object,
    required: true
  }
})

const chartRef = ref(null);
let myChart;

// 获取当前时间
const today = new Date();
const timestamp = today.getTime();
// 定义两天的毫秒数
const twoDay = 24 * 60 * 60 * 1000 * 2;

// 生成15天日期数组
const dates = [];
for (let i = 0; i < 15; i++) {
  // 计算目标日期的时间戳（向前推i天）
  const targetTimestamp = timestamp - i * twoDay;

  // 将时间戳转化为Date对象
  const targetDate = new Date(targetTimestamp);

  // 格式化Date对象为字符串（例如："2023-10-06"）
  const dateString = targetDate.toISOString().slice(0, 10);

  // 将日期字符串添加到数组中
  dates.push(dateString);
}

onUpdated(() => {
  initChart();
});



const initChart = () => {
  const total = Array.from(props.dynamicProp[0].data)
  const amount = Array.from(props.dynamicProp[1].data)
  myChart = echarts.init(chartRef.value);
  const option = {
    title: {
      text: '近30天销售信息',
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['销售笔数', '销售额'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: dates.reverse(),
    },
    yAxis: [
      {
        type: 'value',
        name: '销售笔数(笔)',
        position: 'left',
      },
      {
        type: 'value',
        name: '销售额(元)',
        position: 'right',
      },
    ],
    series: [
      {
        name: '销售笔数',
        type: 'line',
        yAxisIndex: 0,
        data: total,
      },
      {
        name: '销售额',
        type: 'line',
        yAxisIndex: 1,
        data: amount,
      },
    ],
  };

  myChart.setOption(option);
};

</script>
